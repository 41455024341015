import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { styles } from "../utils"
//import LanguageContext from "../contexts/LanguageContext"

const Footer = () => {
  //const { lang } = useContext(LanguageContext)

  return (
    <FooterWrapper>
      <TextWrapper>
        <LinkWrapper>
          <Link to="/sitemap.xml">{"Plan du site"}</Link>
          <Link to="/" className="legal">
            {"Mentions légales"}
          </Link>
          <Link to="/" className="legal">
            {"Accueil"}
          </Link>
        </LinkWrapper>
        <InfoWrapper>
          <p>© 2019 | {"Mathias Béraud"}</p>
          <p className="siret">SIRET: 850 305 954 00016</p>
        </InfoWrapper>
      </TextWrapper>
      {/* <LogoWrapper>
        <Link to="/" style={{ display: "flex", textDecoration: "none" }}>
          <Logo>master coda_</Logo>
        </Link>
      </LogoWrapper> */}
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  background-color: ${styles.colors.darkGrey};
  color: ${styles.colors.white};
  height: 18rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4rem;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    justify-content: center;
    height: 22rem;
    padding-top: 0;
  }
`
const InfoWrapper = styled.div`
  color: ${styles.colors.lightGrey};
  font-size: 1.4rem;
  width: 60%;
  align-self: start;
  text-align: right;
  .siret {
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
    margin: 1rem auto;
    text-align: center;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  width: 40%;
  align-self: start;
  .legal {
    margin-top: 1rem;
  }
  a {
    text-decoration: none;
    color: ${styles.colors.lightGrey};
    ${styles.transObject({ type: "ease-in-out" })};
    &:hover {
      color: ${styles.colors.primary};
    }
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    width: 80%;
    margin: 1rem auto;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex: 2;
`

const Logo = styled.h3`
  color: ${styles.colors.primary};
  padding: 0.6rem 0.6rem;
  text-align: center;
  background-color: ${styles.colors.darkGrey};
  border: solid 1px;
  text-decoration: none;
  border-radius: 2px;
  font-weight: 400;
  font-family: "Courier New", Courier, monospace;
  font-size: 2rem;
  align-self: center;
`
const TextWrapper = styled.div`
  background-color: ${styles.colors.darkGrey};
  color: ${styles.colors.white};
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`

export default Footer
