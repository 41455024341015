import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

const Section = ({ children, color, zIndex, pos }) => {
  return (
    <SectionWrapper color={color} zIndex={zIndex} pos={pos}>
      <SectionContainer>{children}</SectionContainer>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  max-width: 100%;
  background-color: ${props =>
    props.color ? props.color : styles.colors.white};
  z-index: ${props => props.zIndex};
  position: ${props => (props.pos ? props.pos : "static")};
`
const SectionContainer = styled.div`
  max-width: 1050px;
  padding: 0 3.5rem;
  margin: 0 auto;
  position: relative;
`

export default Section
