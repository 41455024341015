import React, { useContext } from "react"
import styled from "styled-components"
import { styles, Banner, content } from "../utils"
//import LanguageContext from "../contexts/LanguageContext"

const Header = () => {
  //const { lang } = useContext(LanguageContext)

  return (
    <HeaderWrapper>
      <Banner bannerTitle={content.Header.title.fr} />
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  background-color: ${styles.colors.darkGrey};
  height: calc(100vh - 12rem);
  position: relative;
`

export default Header
