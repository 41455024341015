import React, { Component } from "react"
import styled from "styled-components"
import NavbarHeader from "./NavbarHeader"
import NavbarLinks from "./NavbarLinks"

export default class Navbar extends Component {
  // state = {
  //   navbarOpen: false,
  // }

  // handleNavbar = () => {
  //   this.setState(() => {
  //     return { navbarOpen: !this.state.navbarOpen }
  //   })
  // }

  render() {
    return (
      <NavBarWrapper>
        <NavbarHeader
          handleNavbar={this.props.handleNavbar}
          navbarOpen={this.props.navbarOpen}
        />
        <NavbarLinks
          navbarOpen={this.props.navbarOpen}
          handleNavbar={this.props.handleNavbar}
        />
      </NavBarWrapper>
    )
  }
}

const NavBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12rem;
`
