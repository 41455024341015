import * as styles from "./styles"
import * as content from "./content"
import Header from "./Header"
import Banner from "./Banner"
import Section from "./Section"
import Title from "./Title"
import Paragraph from "./Paragraph"
import translate from "./translate"
import Footer from "./Footer"
import PageHeader from "./PageHeader"

export {
  styles,
  content,
  translate,
  Header,
  Banner,
  Section,
  Title,
  Paragraph,
  Footer,
  PageHeader,
}
