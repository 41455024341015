import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

const Paragraph = ({ children }) => {
  return <ParagraphWrapper>{children}</ParagraphWrapper>
}

const ParagraphWrapper = styled.p`
  text-align: left;
  margin: 1rem 0 2rem;
  line-height: 1.2;
  a:link,
  a:visited {
    font-weight: bold;
    color: ${styles.colors.primaryFont};
    text-decoration: none;
    position: relative;
    z-index: 0;
    white-space: nowrap;
    display: inline-block;
    padding: 0 0.2rem;
    ${styles.transDefault};
  }
  a:hover {
    color: ${styles.colors.primary};
    background-color: ${styles.colors.darkGrey};
    &::after {
      display: none;
    }
  }
  a::after {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: ${styles.colors.primary};
    width: 100%;
    height: 5px;
    opacity: 0.3;
  }
`

export default Paragraph
