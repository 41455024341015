import React from "react"
import { styles } from "../utils"
import styled from "styled-components"

const Title = props => {
  return (
    <TitleWrapper>
      <TitleContent>{props.titleText}</TitleContent>
      <TitleSparkles>&nbsp;</TitleSparkles>
    </TitleWrapper>
  )
}
const TitleWrapper = styled.div`
  margin-bottom: 3rem;
`

const TitleContent = styled.h2`
  font-size: 3.5rem;
`

const TitleSparkles = styled.div`
  width: 2.3rem;
  height: 3px;
  background-color: ${styles.colors.primary};
  margin-top: -5px;
`

export default Title
