function translate(array, position, context) {
  let result = array.map(item => {
    if (
      item.node.node_locale.substring(0, 2) === context &&
      item.node.position === position
    ) {
      return item.node.content
    }
    return ""
  })
  return result
}

export default translate
