/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "typeface-nunito"
import { createGlobalStyle } from "styled-components"

import { Section, styles, Footer } from "../utils"

//import { LanguageStore } from "../contexts/LanguageContext"
//import LanguageSelector from "./LanguageSelector"

import Navbar from "../components/globals/navbar/Navbar"

class Layout extends React.Component {
  // state = { language: this.defineLanguage(), navbarOpen: false }
  state = { navbarOpen: false }

  handleNavbar = () => {
    this.setState(() => {
      return { navbarOpen: !this.state.navbarOpen }
    })
  }

  // onLanguageChange = language => {
  //   this.setState({ language })
  // }

  componentDidUpdate() {
    if (this.state.navbarOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }

  // componentDidMount() {
  //   if (
  //     window.location.pathname.substring(0, 7) === "/posts/" ||
  //     window.location.pathname.substring(0, 11) === "/portfolio/"
  //   ) {
  //     return this.setState(() => {
  //       return { languageSwitcher: false }
  //     })
  //   }
  //   return this.setState(() => {
  //     return { languageSwitcher: true }
  //   })
  // }

  render() {
    // if (!this.state.languageSwitcher) {
    //   return (
    //     <>
    //       <GlobalStyle />
    //       <Section color={styles.colors.darkGrey} zIndex="1" pos="relative">
    //         <Navbar
    //           handleNavbar={this.handleNavbar}
    //           navbarOpen={this.state.navbarOpen}
    //         />
    //       </Section>
    //       {this.props.children}

    //       <Section color={styles.colors.darkGrey}>
    //         <Footer />
    //       </Section>
    //     </>
    //   )
    // }
    return (
      <>
        <GlobalStyle />
        <Section color={styles.colors.darkGrey} zIndex="1" pos="relative">
          <Navbar
            handleNavbar={this.handleNavbar}
            navbarOpen={this.state.navbarOpen}
          />
        </Section>
        {this.props.children}

        <Section color={styles.colors.darkGrey}>
          <Footer />
        </Section>
      </>
    )
  }
}

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  /* This define what 1rem is: 62.5% of 16px(default) => 10px */
  html {
    font-size: 62.5%;
    @media screen and (max-width: 768px) {
      font-size: 56.25%;
    }

  }

  body {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    /* font-family: "Courier New", Courier, monospace; */
    /* font-family: "Lato", sans-serif; */
    color: #262626;
    background: ${styles.colors.white};
    font-size: 1.6rem;
    
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
