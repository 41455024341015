import React from "react"
import styled from "styled-components"
import { styles } from "../utils"

const Banner = props => {
  return (
    <BannerWrapper>
      <h1>{props.bannerTitle}</h1>
    </BannerWrapper>
  )
}

const BannerWrapper = styled.div`
  font-size: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    font-weight: 200;
    color: ${styles.colors.lightGrey};
  }
`

export default Banner
