import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { styles } from "../../../utils"
// import LanguageContext from "../../../contexts/LanguageContext"

export default class NavbarLinks extends Component {
  // static contextType = LanguageContext
  state = {
    links: [
      {
        id: 0,
        path: "#details",
        name: "Détails",
        nameEn: "Details",
      },
      {
        id: 1,
        path: "#portfolio",
        name: "Portfolio",
        nameEn: "Portfolio",
      },
      {
        id: 2,
        path: "#technologies",
        name: "Technologies",
        nameEn: "Technologies",
      },
      {
        id: 3,
        path: "/posts",
        name: "Blog",
        nameEn: "Blog",
      },
      {
        id: 4,
        path: "#contact",
        name: "Contact",
        nameEn: "Contact",
      },
    ],
  }

  render() {
    return (
      <NavWrapper open={this.props.navbarOpen}>
        <LinkWrapper
          open={this.props.navbarOpen}
          onClick={() => {
            /* Logique pour éviter de call handleNavbar en mode desktop */
            if (this.props.navbarOpen) {
              return this.props.handleNavbar()
            }
          }}
        >
          {this.state.links.map(item => {
            return (
              <li key={item.id}>
                <Link to={item.path} className="nav-link">
                  {item.name}
                </Link>
              </li>
            )
          })}
        </LinkWrapper>
      </NavWrapper>
    )
  }
}

const LinkWrapper = styled.ul`
  display: flex;
  li {
    list-style-type: none;
    margin-right: 1.5rem;
    .nav-link {
      text-decoration: none;
      color: ${styles.colors.lightGrey};
      padding: 0.5rem 0 1.5rem;
      letter-spacing: 0.5px;
      ${styles.transObject({ time: "0.5s", type: "ease-in-out" })};
      &:hover {
        color: ${styles.colors.primary};
      }
    }
    @media screen and (min-width: 992px) {
      margin-right: 2rem;
    }
  }
  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    li {
      margin-bottom: 2rem;
      font-size: 3rem;
      font-weight: 100;
      padding: 0.2rem 1rem;
      text-align: center;
      ${styles.transObject({ type: "ease-in-out" })};
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        ${styles.colors.darkGrey} 50%
      );
      background-size: 215%;
      &:hover,
      &:active {
        background-position: 100%;
        transform: translateX(1rem);
        .nav-link {
          color: ${styles.colors.primary};
        }
      }
    }
  }
`

const NavWrapper = styled.nav`
  @media screen and (max-width: 768px) {
    display: none;
    ${props =>
      props.open
        ? `display: block; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);`
        : ""};
  }
`
