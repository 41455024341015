export const colors = {
  primary: `#26BF86`,
  primaryTransparent: `rgba(38, 191, 134, 0.2)`,
  primaryLight: `#90FF63`,
  secondary: `#666`,
  primaryFont: `#000`,
  secondaryFont: `#FFF`,
  white: `#FFF`,
  black: `#000`,
  darkGrey: `#262626`,
  mediumGrey: `#474747`,
  lightGrey: `#E9E9E9`,
}

export const transDefault = "transition: all 0.2s ease-in-out"

export const transObject = ({
  property = "all",
  time = "0.2s",
  type = "linear",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const border = ({
  width = "0.15rem",
  type = "solid",
  color = colors.mainWhite,
}) => {
  return `border: ${width} ${type} ${color}`
}

export const letterSpacing = ({ spacing = "0.1rem" }) => {
  return `letter-spacing: ${spacing}`
}
