import React, { Component } from "react"
import styled from "styled-components"
import { styles } from "../../../utils"

class NavbarToggle extends Component {
  render() {
    return (
      <BurgerWrapper
        onClick={() => {
          this.props.handleNavbar()
        }}
      >
        <BurgerButton open={this.props.navbarOpen}>
          <div className="burger-top"></div>
          <div className="burger-mid"></div>
          <div className="burger-bot"></div>
        </BurgerButton>
        <BurgerBackground open={this.props.navbarOpen}>&nbsp;</BurgerBackground>
      </BurgerWrapper>
    )
  }
}

const BurgerButton = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 3rem;
  right: 3rem;
  background-color: ${styles.colors.mediumGrey};
  z-index: 2000;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    width: 3rem;
    height: 2px;
    background-color: ${styles.colors.primary};
    position: absolute;
    top: 50%;
    left: 50%;
    ${styles.transObject({})};
    transform: translate(-50%, -50%);
  }
  .burger-bot {
    margin-top: ${props => (props.open ? "0" : "1rem")};
    transform: ${props =>
      props.open
        ? "translate(-50%, -50%) rotate(-45deg)"
        : "translate(-50%, -50%)"};
  }
  .burger-top {
    transform: ${props =>
      props.open
        ? "translate(-50%, -50%) rotate(45deg)"
        : "translate(-50%, -50%)"};
    margin-top: ${props => (props.open ? "0" : "-1rem")};
  }
  .burger-mid {
    ${styles.transObject({ time: "0.3s", type: "ease-in-out" })};
    transform: ${props =>
      props.open ? "translate(200px, -50%)" : "translate(-50%, -50%)"};
    opacity: ${props => (props.open ? "0" : "1")};
  }
`

const BurgerBackground = styled.div`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: fixed;
  top: 3.5rem;
  right: 3.5rem;
  background-image: radial-gradient(
    ${styles.colors.mediumGrey},
    ${styles.colors.darkGrey}
  );
  ${styles.transObject({ time: "0.1s" })};
  transform: ${props => (props.open ? "scale(100)" : "none")};
  z-index: 1000;
`

const BurgerWrapper = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 3rem;
  right: 3rem;
  @media screen and (min-width: 769px) {
    display: none;
  }
`

export default NavbarToggle
