import React, { Component } from "react"
import { Link } from "gatsby"
//import logo from "../../../images/logo.svg"
import NavbarToggle from "./NavbarToggle"
import styled from "styled-components"
import { styles } from "../../../utils"

export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar } = this.props
    return (
      <HeaderWrapper>
        <Link to="/" style={{ display: "flex", textDecoration: "none" }}>
          <LogoWrapper>{`master coda_`}</LogoWrapper>
        </Link>
        <NavbarToggle
          navbarOpen={this.props.navbarOpen}
          handleNavbar={handleNavbar}
        />
      </HeaderWrapper>
    )
  }
}

const HeaderWrapper = styled.div`
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoWrapper = styled.h3`
  color: ${styles.colors.primary};
  padding: 0.6rem 0.6rem;
  text-align: center;
  background-color: ${styles.colors.darkGrey};
  border: solid 1px;
  text-decoration: none;
  border-radius: 2px;
  font-weight: 400;
  font-family: "Courier New", Courier, monospace;
  font-size: 2rem;
`
