export const Header = {
  title: {
    fr: "Développement d'applications et de sites web efficaces et performants",
    en: "Simple, effective and powerful websites and web applications",
  },
}

export const Presentation = {
  title: {
    fr: "Présentation",
    en: "Presentation",
  },
  subtitle: {
    fr: "<design web / développement web>",
    en: "<web design / web development>",
  },
  para1: {
    fr:
      "Bonjour, je m'appelle Mathias Béraud, je suis un développeur / designer web freelance basé dans la Drôme en France.",
    en:
      "Hi, my name is Mathias Béraud, I'm a freelance web developer / designer based in the Drôme in France",
  },
  para2start: {
    fr: "Passionné de nouvelles technologies, je suis adepte de la",
    en: "I'm into new technologies and I'm a big proponent of the",
  },
  para2link: {
    text: "JAMSTACK",
    link: "https://jamstack.org/",
  },
  para2end: {
    fr:
      ", par conséquent je travaille principalement en GatsbyJS et React qui représentent selon moi l'avenir du développement web.",
    en:
      ", therefore I prefer to work with GatsbyJS and React which are, I believe, the future of web development.",
  },
  para3start: {
    fr: "Après quelques années dans une agence Bruxelloise (",
    en: "After a couple years in a Brussels agency (",
  },
  para3link: {
    text: "R+S",
    link: "https://riseandshine.agency",
  },
  para3end: {
    fr:
      ") j'ai fait le choix de revenir vivre en France et de démarrer master coda.",
    en: ") I chose to move back to France and started master coda.",
  },
  para4: {
    fr:
      "Que vous soyez une startup ayant besoin d'un site vitrine, une entreprise voulant un site d'e-commerce, ou un entrepreneur avec une super idée d'application web, n'hésitez pas à ",
    en:
      "Whether you're a startup in need of a basic website, an enterprise looking for a web shop, or an entrepreneur with a great web application idea, feel free to ",
  },
  para4link: {
    text: {
      fr: "me contacter",
      en: "contact me",
    },
    link: "#contact",
  },
}

export const Details = {
  title: {
    fr: "Détails",
    en: "Details",
  },
  intro: {
    fr:
      "Créer une relation de confiance est essentiel au succès de toute collaboration, par conséquent, les aspects suivants font partie intégrante de mes projets: ",
    en:
      "Building a relationship of trust is essential to every successful collaboration, therefore, the following aspects are all parts of my projects: ",
  },
  first: {
    title: {
      fr: "Premier rendez-vous non contractuel",
      en: "Non-binding introductory meeting",
    },
    para: {
      fr:
        "Le premier rendez-vous est toujours gratuit et non contractuel, il peut s'effectuer via vidéoconférence ou en personne (dans la mesure du possible). Son but est de définir le cadre du projet et de discuter des solutions techniques éventuelles. Si je ne réponds pas à vos attentes pour une raison X ou Y, c'est bien dommage, mais ce n'est en aucun cas un problème.",
      en:
        "The first meeting is always free and non-binding, it can happen over Skype or in person (to the extent possible). It serves the purpose of defining the scope of the project and talking about possible solutions. If the expectations are not met in the end for whatever reason, that's unfortunate, but definitely not a problem.",
    },
  },
  second: {
    title: {
      fr: "Tarification transparente",
      en: "Transparent pricing",
    },
    para: {
      fr:
        "La tarification restera transparente à toute étape du projet. Explication: je ne facture pas à l'heure, nous nous mettrons d'accord sur un devis avec un tarif fixe. Le tarif est ajusté uniquement en cas de changement de la portée du projet et selon les fonctionnalités additionelles requises. Ainsi, le tarif final reste toujours clair et personne n'aura de mauvaises surprises.",
      en:
        "The price will be fully transparent at any stage of the project. That means: I will not charge on an hourly basis, but rather we will agree on a fixed price. Only if the scope of the project changes will the price be adjusted accordingly with prior agreement. Therefore, it's always clear on what the final price will be, and there will be no surprises",
    },
  },
  third: {
    title: {
      fr: "Garantie de satisfaction",
      en: "Satisfaction guarantee",
    },
    para: {
      fr:
        "Le versement est effectué après la complétion du projet et la satisfaction du client par rapport à celui-ci. Pour la simple et bonne raison que le respect des attentes du client est ma priorité.",
      en:
        "Payment is only due after the completion of the project and satisfaction of the customer. This is for the simple reason that money should only be paid when expectations are met.",
    },
  },
  fourth: {
    title: {
      fr: "Des solutions à la pointe de la technologie",
      en: "State-of-the-art solutions",
    },
    para: {
      fr:
        "Nous sommes en 2019 et cela doit transparaître dans le produit final. Ce qui signifie davantage que des sites adaptés aux mobiles et compatibles sur tout les navigateurs. Cela implique les meilleures pratiques concernant la performance, la sécurité, l'accessibilité et l'ergonomie. Le résultat est un design pertinent, un site sécurisé, des chargements éclairs et des utilisateurs heureux. Ce sont en définitive les manières les plus efficaces et les plus sûres d'optimiser son référencement.",
      en:
        "It's 2019 and you should see that in the final product. This means much more than responsiveness and cross-browser-compatibility and involves best practices regarding performance, accessibility, security and usability. That results in appropriate design, secure websites, fast load times and happy customers. Ultimately, this is the best and most reliable way to achieve Search Engine Optimization (SEO).",
    },
  },
  fifth: {
    title: {
      fr: "Une relation personnelle",
      en: "Personal relationship",
    },
    para: {
      fr:
        "Vous n'aurez pas affaire à une grosse agence web mais à une seule personne dédiée à la réussite de votre projet. Je serais en contact avec vous tout au long de l'avancement et à votre disposition pour toute requête ou question. Si d'aventure une requête dépasse mes compétences, je peux faire appel à une communauté de freelance compétents et vous aider à choisir la personne idéale.",
      en:
        "You won't be dealing with a big web agency but rather with a fully dedicated 1-man-show. This makes the relationship more personal, as I will be your contact person throughout the entire process for any inquiries or requests. If you have any requests that go beyond my range of services, I can readily rely on a network of competent freelancers.",
    },
  },
  sixth: {
    title: {
      fr: "Où que vous en soyez dans votre projet",
      en: "Wherever your are with your project",
    },
    para: {
      fr:
        "Vous avez un concept bien ancré et une idée précise de votre projet? Vous avez une vague idée de votre projet et avez besoin de support pour le concept ou l'implémentation? Vous avez déjà un site et n'êtes pas pleinement satisfait? Où que vous en soyez dans votre idée ou projet, je serais heureux de vous aider et de discuter des éventuelles étapes à suivre.",
      en:
        "You have a finished concept and a clear picture of your project? You have a rough idea of your project and need support regarding concept or implementation? You have a website but aren't fully satisfied? Wherever you are with your project, I'll be happy to help and discuss the futher steps to be taken.",
    },
  },
}

export const Technologies = {
  title: {
    fr: "Technologies",
    en: "Technologies",
  },
  para1: {
    partOne: {
      fr:
        "Le web est en constante évolution, et, avec lui, les possibilités d'implémentations. Des mots-clés tels que ",
      en:
        "The web is changing rapidly and with it the possibilities to implement websites and web applications. Keywords like ",
    },
    links: {
      jamstack: {
        text: "JAMSTACK",
        link: "https://jamstack.org/",
      },
      serverless: {
        text: "Serverless",
        link: "https://serverless.css-tricks.com/",
      },
      static: {
        text: {
          fr: "Générateurs de Sites Statiques",
          en: "Static Site Generators",
        },
        link: "https://www.staticgen.com/",
      },
      headless: {
        text: {
          fr: "CMS Headless",
          en: "Headless CMS",
        },
        link: "https://headlesscms.org/",
      },
    },
    partTwo: {
      fr: " décrivent cette nouvelle approche.",
      en: " describe this new, modern approach.",
    },
  },
  para2: {
    fr:
      "Jusqu'à récemment, la création de sites web dynamiques avec des outils tels que Wordpress était pratique courante et le résultat était souvent peu flexible et poussif. Grâce aux technologies modernes il est désormais possible de faire bien mieux!",
    en:
      "Until recently, creating dynamic websites with tools like Wordpress was common practice. Today this approach often seems inflexible and clunky compared to modern technologies, especially regarding the preformance of the resulting website.",
  },
  para3: {
    partOne: {
      fr: "Les librairies Javascript telles que ",
      en: "Javascript libraries like ",
    },
    links: {
      gatsby: {
        text: "GatsbyJS",
        link: "https://www.gatsbyjs.org/",
      },
      react: {
        text: "React",
        link: "https://reactjs.org/",
      },
    },
    partTwo: {
      fr: " et les Frameworks Front End modernes tels que ",
      en: " and modern Front End Frameworks like ",
    },
    partThree: {
      fr:
        " premettent la création de ressources statiques dotées de toutes les possibilités des applications dynamiques. On obtient en quelque sorte, le meilleur des deux mondes. Cela implique une panoplie d'avantages concernant les performances, la flexibilité, la sécurité, la modularité et le coût du site web résultant. Permettant le meilleur résultat possible.",
      en:
        " allow for the creation of static assets with all the possibilites dynamic web apps offer. So you'll get the best of both worlds. That entails a range of advantages, especially regarding the performance, the flexibility, the security, the scalability and the costs of the resulting website. All of this allows for the best possible result.",
    },
  },
}

export const Contact = {
  para1: {
    fr:
      "Vous avez une idée de site ou d'application web que vous voulez implémenter? Vous avez un site web et voulez faire quelques changement? Je serais heureux de vous aidez peu importe où vous en êtes!",
    en:
      "You have an idea about a web application or a website that you want to implement? You have a website and would like to make some change? I'll be happy to help regardless of where you are in your project!",
  },
}

export const BlogPresentation = {
  fr:
    "Salut, c'est Mathias je suis développeur web freelance et j'écris des tutoriels à propos des technologies Javascript, de la JAMSTACK et d'autres trucs.",
  en:
    "Hey, I'm Mathias, a freelance web developer. I write tutorials about Javascript technologies, the JAMSTACK and some other stuff.",
}
