import React, { useContext } from "react"
import styled from "styled-components"
import { styles, Banner, content } from "../utils"
//import LanguageContext from "../contexts/LanguageContext"

const PageHeader = props => {
  return (
    <HeaderWrapper>
      <Banner bannerTitle={props.title} />
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  background-color: ${styles.colors.darkGrey};
  height: 20rem;
  position: relative;
`

export default PageHeader
